import { useEffect, useMemo, useState } from 'react';
import { breakpoints } from 'helpers/hooks/useResponsive';
import { convertToTitleCase, pxToRem } from 'helpers/utils/misc';
import styled from 'styled-components';
import { getUserName } from '../controllers/useUsers';
import cns from 'classnames';
import { Spinner } from 'react-bootstrap';
import SearchBox from 'components/ui/SearchBox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BlurredImage from 'components/ui/BlurredImage';
import { AppDispatch, RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { handleBlurImage, searchChatList, selectChatHandler } from '../../../redux/slices/chatSlice';
import { Invite, Job, Proposal } from '../../../redux/slices/chat.interface';
import * as I from '../../../redux/slices/chat.interface';
import { useAuth } from 'helpers/contexts/auth-context';
import queryString from 'query-string';

const Wrapper = styled.div`
  padding: ${pxToRem(6)};
  max-height: 510px;
  overflow-y: auto;
  @media ${breakpoints.tablet} {
    max-height: 350px;
  }
`;

const StyledUser = styled.div`
  padding: ${pxToRem(20)};
  /* justify-content: space-around; */
  gap: ${pxToRem(12)};
  cursor: pointer;
  &:hover {
    background-color: #fbf5e8;
  }
  .userlistitem__avatar {
    img {
      border-radius: 50%;
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
  }
  .userlistitem__count {
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .userlistitem--info-name {
    color: #000;
    font-size: 1rem;
  }
  .userlistitem--info-msg {
    opacity: 0.5;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 130px;
    display: block;
    overflow: hidden;
  }
  &.active {
    background-color: #fbf5e8;
  }
  .closed-project {
    color: ${(props) => props.theme.colors.primary};
  }
`;

interface Prop {
  proposalId?: string;
  inviteId?: string;
}

export const getUsersListActions = {
  proposals: 'pd_get_user_list',
  jobs: 'get_user_list',
  invites: 'invite_get_user_list',
};

function UserList() {
  const { user } = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { invite_id, proposal_id } = queryString.parse(location.search);

  const { chatList, loading, activeTab, activeChat, unreadMessages, search } = useSelector(
    (state: RootState) => state.chat
  );
  const totalMessages = unreadMessages.invities + unreadMessages.jobs + unreadMessages.proposals;

  // const [searchTerm, setSearchTerm] = useState<string>('');

  const onSelectChatHandler = (chatItem: Invite & Proposal & Job, index: number) => {
    if (loading.message) return;

    const remoteUserId =
      user.user_id !== chatItem._from_user_data.user_id ? chatItem._from_user_data : chatItem._to_user_data;

    dispatch(selectChatHandler({ chatItem, index, remoteUserId: remoteUserId.user_id }));
  };

  const paramsChatSelectHandler = () => {
    if (chatList[activeTab].length === 0) return;

    const flag = invite_id ? 'invities' : 'proposals';
    const id: number = flag === 'invities' ? Number(invite_id) : Number(proposal_id);

    if (typeof id !== 'number' || chatList[flag].length === 0) return;

    chatList[activeTab].map((chat: Invite & Job & Proposal, index: number) => {
      if ((flag === 'invities' && chat.invite_id === id) || (flag === 'proposals' && chat.proposal_id === id)) {
        onSelectChatHandler(chat, index);
        navigate('/messages');
      }
    });
  };

  useEffect(() => {
    paramsChatSelectHandler();
  }, [chatList, activeTab]);

  return (
    <>
      <header className="m-panel--header">
        <span className="title">Inbox</span>
        {totalMessages > 0 && <span className="fs-20 fw-400 ms-1">({totalMessages})</span>}
        {/* {users?.unseen_count > 0 && <span className="fs-20 fw-400 ms-1">({users.unseen_count})</span>} */}
      </header>
      <SearchBox
        value={search.chatList}
        onChange={(e) => dispatch(searchChatList({ searchTerm: e.target.value }))}
        enableBorder={true}
      />
      <Wrapper>
        {loading.list ? (
          <div className="text-center">
            <Spinner animation="grow" />
            <p>Loading chats...</p>
          </div>
        ) : (
          <>
            {chatList[activeTab].map((usr: Invite & Job & Proposal, index: number) => {
              if (search.chatList) {
                const remoteUser =
                  user.user_id !== usr._from_user_data.user_id ? usr._from_user_data : usr._to_user_data;

                const remoteUserFullName = `${remoteUser.first_name} ${remoteUser.last_name}`.toLocaleLowerCase();

                if (!remoteUserFullName.includes(search.chatList.toLocaleLowerCase())) return <></>;
              }
              return (
                <UserListItem
                  data={usr}
                  className={cns({
                    active: usr._job_post_id === activeChat?._job_post_id && usr.proposal_id === activeChat.proposal_id,
                  })}
                  key={`${usr._job_post_id}_${index}`}
                  onSelectChat={() => onSelectChatHandler(usr, index)}
                />
              );
            })}
          </>
        )}

        {!loading.list && chatList[activeTab].length === 0 ? <div className="p-2 fs-18">No Messages</div> : null}
      </Wrapper>
    </>
  );
}

const UserListItem = ({
  className,
  data,
  onSelectChat,
}: {
  className?: string;
  data: Invite & Job & Proposal;
  onSelectChat: () => void;
}) => {
  const { user, isLoading } = useAuth();

  // Only showing it to freelancer and proposals tab in message page
  // If project was deleted or assigned to someone else then it'll show "Closed"
  const isClosedorDeclined = useMemo(() => {
    if (data?.proposal_status || data?.invite_status)
      if (['deleted', 'active', 'closed'].includes(data?.job_status)) {
        return 'Closed';
      } else if (data?.proposal_status === 'denied') {
        return 'Declined';
      } else if (data?.invite_status === 'canceled') {
        return 'Canceled';
      }
    return '';
  }, [data]);

  const { activeChat, activeTab } = useSelector((state: RootState) => state.chat);
  const [showImg, setShowImg] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const remoteUser = user.user_id !== data._from_user_data.user_id ? data._from_user_data : data._to_user_data;

  const handleBlur = () => {
    let check: boolean = false;

    if (!activeChat) return;
    if (activeTab === 'jobs' && activeChat._job_post_id === data._job_post_id) {
      check = true;
    }

    if (activeTab === 'proposals' && activeChat.proposal_id === data.proposal_id) {
      check = true;
    }

    if (activeTab === 'invities' && activeChat.invite_id === data.invite_id) {
      check = true;
    }

    if (check === true) dispatch(handleBlurImage(showImg));
  };

  useEffect(() => {
    handleBlur();
  }, [showImg]);

  if (isLoading) return <></>;

  return (
    <StyledUser
      className={`d-flex align-items-center ${className}`}
      onClick={onSelectChat}
      title={`Project: ${convertToTitleCase(data.job_title)}`}
    >
      <div className="userlistitem__avatar chat-user-list">
        <BlurredImage
          state={[showImg, setShowImg]}
          src={remoteUser?.user_image || '/images/default_avatar.png'}
          height="48px"
          width="48px"
          overlayText="Click to view"
        />
      </div>
      <div className="userlistitem__info flex-1">
        <div
          className={cns('userlistitem--info-name text-capitalize', {
            'fw-700': data.unread_count > 0,
          })}
        >
          {remoteUser ? getUserName(remoteUser) : ''}
        </div>
        <div className="userlistitem--info-msg capital-first-ltr">
          <span>Project: {convertToTitleCase(data.job_title)}</span>
        </div>
        {isClosedorDeclined && <span className="closed-project">{isClosedorDeclined}</span>}
      </div>
      {data.unread_count > 0 ? <div className="userlistitem__count">{data.unread_count}</div> : null}
    </StyledUser>
  );
};

export default UserList;
