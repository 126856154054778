import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { breakpoints } from 'helpers/hooks/useResponsive';

export const HeaderWrapper = styled.div`
  padding: 1.5rem 0rem 1.5rem 0rem;
  .divider {
    width: 1.37875rem;
    transform: rotate(90deg);
    border: 1px solid #d9d9d9;
  }
  .navbar-nav .nav-link {
    color: #000;
    margin: 0rem 1rem 0rem 0rem;
    @media (max-width: 1024px) {
      margin: 0;
      font-size: 1rem;
    }
    @media ${breakpoints.desktop} {
      &:after {
        content: '';
        background: transparent;
        height: 2px;
        display: block;
        width: 0;
        transition: all 0.2s ease-in-out;
        margin: auto;
        position: relative;
        top: 3px;
      }
      :hover {
        &:after {
          width: 80%;
          background: currentColor;
        }
      }
    }
  }
  .search-link {
    margin: 0rem 1rem;
  }
  .search {
    color: #000;
    margin: 0rem 0.625rem;
    @media ${breakpoints.mobile} {
      font-size: 0.875rem;
    }
  }
  .searchLink {
    &:after {
      display: none !important;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .profile-img {
    height: 48px;
    width: 48px;
    border-radius: 100%;
    object-fit: cover;
  }
  .loggedin-username {
    line-height: 1.35rem;
    margin-left: 0.75rem;
    /* max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
  }
  .dropdown-arrow {
    margin-left: 0.625rem;
  }
  .msg-count {
    background-color: ${(props) => props.theme.colors.yellow};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
  .notification-bell {
    height: 60px;
    width: 60px;
    cursor: pointer;
  }
`;

export const Main = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fefbf4;
  .header-bottom-border {
    background: #f2b420;
    height: 1px;
    width: 100%;
  }
  .account-not-approved {
    display: flex;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.yellow};
    border-top: none;
    background-color: rgba(251, 245, 232, 1);
    &.rejected {
      border: 1px solid ${(props) => props.theme.colors.red};
      color: ${(props) => props.theme.colors.red};
    }
    .faq-link {
      &:hover {
        color: ${(props) => props.theme.colors.primary};
        text-decoration-color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const UserDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    color: inherit;
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    border: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 25%);
    border-radius: 0.5rem;
    .dropdown-item {
      padding: 0;
      &:hover,
      &:active,
      &:focus,
      &:visited {
        background-color: transparent !important;
        color: ${({ theme }) => theme.colors.blue};
      }
      .logout {
        /* margin-top: 0.5rem; */
        color: ${({ theme }) => theme.statusColors?.darkPink?.color};
      }
    }
    .menu-item {
      padding: 10px 20px;
    }
  }
`;

export const UserDropdownToggle = styled.div``;

export const JewishText = styled.p`
  margin-bottom: 0;
  margin-left: 0.5rem;
  font-weight: bold;
  font-size: 20px;
  text-wrap: nowrap;
  margin-right: 1.5rem;
  margin-left: 1rem;
`;
