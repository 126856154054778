import { breakpoints } from 'helpers/hooks/useResponsive';
import { pxToRem } from 'helpers/utils/misc';
import styled from 'styled-components';

export const MessageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.75rem;
  margin: 1.5rem auto;
  display: flex;
  box-shadow: 0px 4px 74px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  @media ${breakpoints.tablet} {
    flex-direction: column;
  }
  .m-panel--header {
    min-height: 70px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
    padding: 0 2rem;
  }
  .title {
    line-height: 70px;
    font-size: 1.5rem;
  }
  .m-userlist--wrapper {
    width: ${pxToRem(285)};
    border-right: 1px solid ${(props) => props.theme.colors.gray6};
    @media ${breakpoints.tablet} {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray6};
      padding-bottom: 1rem;
    }
  }
`;

export const TabsContainer = styled.div`
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  .msg-count {
    background-color: ${(props) => props.theme.colors.yellow};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
`;

export const MessageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
`;
