import { useEffect, useRef } from 'react';
import { MessageContainer } from './messaging.styled';
import ChatPanel from './partials/ChatPanel';
import { useAuth } from 'helpers/contexts/auth-context';
import { AppDispatch, RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchChatList, selectChatHandler } from '../../redux/slices/chatSlice';
import axios, { CancelTokenSource } from 'axios';
import * as I from 'redux/slices/chat.interface';
import { Spinner } from 'react-bootstrap';

function SingleMessaging({ id }: { id: string }) {
  const { chatList, loading } = useSelector((state: RootState) => state.chat);
  const dispatch: AppDispatch = useDispatch();
  const cancelTokenRef = useRef<CancelTokenSource>();
  const { user } = useAuth();

  const onSelectChat = (chatItem: I.Invite & I.Proposal & I.Job, index: number) => {
    const remoteUserId =
      user.user_id !== chatItem._from_user_data.user_id ? chatItem._from_user_data : chatItem._to_user_data;

    const payload = { chatItem, index, remoteUserId: remoteUserId.user_id };
    dispatch(selectChatHandler(payload));
  };

  const handleChatToActive = () => {
    // const chatItem: I.Invite & I.Proposal & I.Job = chatList['jobs'].find(
    //   (job: I.Invite & I.Proposal & I.Job) => job._job_post_id === id
    // );
    // const index: number = chatList['jobs'].findIndex((job: I.Invite & I.Proposal & I.Job) => job._job_post_id === id);
    // if (!chatItem) return null;

    // dispatch(selectChatHandler({ chatItem, index }));
    chatList['jobs'].map((chatItem: I.Invite & I.Proposal & I.Job, index: number) => {
      if (chatItem._job_post_id === id) onSelectChat(chatItem, index);
    });
  };

  useEffect(() => {
    if (user.user_id) {
      cancelTokenRef.current = axios.CancelToken.source();
      dispatch(fetchChatList({ cancelToken: cancelTokenRef.current.token }));
    }

    return () => {
      if (cancelTokenRef.current) cancelTokenRef.current.cancel();
    };
  }, [user]);

  useEffect(() => {
    if (chatList.jobs.length > 0) handleChatToActive();
  }, [chatList, user]);

  return (
    // <ChatProvider>
    <MessageContainer>
      <>
        {loading.list || loading.message ? (
          <div
            className="text-center py-5"
            style={{
              flex: 1,
            }}
          >
            <div className="d-flex align-items-center justify-content-center gap-4">
              <Spinner animation="border" />
              <p className="mb-0">loading messages...</p>
            </div>
          </div>
        ) : (
          <ChatPanel showMilestoneAlert={false} isFromSingleMessaging />
        )}
      </>
    </MessageContainer>
  );
}

export default SingleMessaging;

// function ChatPanelWithUser({ id }: { id: string }) {
//   return (
//     <MessageContainer>
//       <ChatPanel />
//     </MessageContainer>
//   );
// }
