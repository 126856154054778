import { ReactComponent as CrossIcon } from 'assets/icons/cross-black.svg';
import { ReactComponent as Search } from 'assets/icons/searchIcon.svg';

const SearchMessages = ({ onClick, onClear, value }: { onClick: () => void; onClear: () => void; value: string }) => {
  return (
    <div className="search-messages">
      <Search />
      <input placeholder={'Search messages'} value={value} onMouseDown={onClick} />
      {value !== '' && <CrossIcon className="pointer" onClick={onClear} />}
    </div>
  );
};

export default SearchMessages;
