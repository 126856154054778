import { breakpoints } from 'helpers/hooks/useResponsive';
import { pxToRem } from 'helpers/utils/misc';
import styled from 'styled-components';
import moment from 'moment';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { UserData } from 'redux/slices/chat.interface';

const Wrapper = styled.div<{ isFromSingleMessaging: boolean }>`
  display: flex;
  position: ${(props) => (props.isFromSingleMessaging ? 'relative' : 'sticky')};
  left: 0px;
  top: -1px;
  margin-left: ${(props) => (props.isFromSingleMessaging ? '4px' : '-20px')};
  margin-right: ${(props) => (props.isFromSingleMessaging ? '4px' : '-18px')};
  width: ${({ isFromSingleMessaging }) => (isFromSingleMessaging === false ? 'max-content' : '100%')};
  z-index: 999;
  .timezone {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: ${(props) => (props.isFromSingleMessaging ? '2rem' : '0px')};
    border: solid rgb(242, 180, 32, 0.16);
    padding: 0px 0.5rem;
    background-color: ${(props) => props.theme.colors.body};
    span {
      font-size: ${pxToRem(14)};
      color: rgba(0, 0, 0, 0.5);
    }
  }
  @media ${breakpoints.tablet} {
    margin: ${(props) => (props.isFromSingleMessaging ? '10px 0px' : '0px -18px 0px -20px')};
    .timezone {
      justify-content: center;
      width: calc(100% + 20px);
    }
  }
`;

type Props = {
  isFromSingleMessaging: boolean;
  remoteUser: Partial<UserData>;
};

export const TimezoneUI = ({ isFromSingleMessaging, remoteUser }: Props) => {
  return (
    <Wrapper isFromSingleMessaging={isFromSingleMessaging}>
      {remoteUser?.timezone && (
        <div className="timezone">
          <Clock stroke="#F2B420" width={14} height={14} />
          <span>
            {remoteUser.user_type === 'freelancer' ? 'Freelancer' : 'Client'}
            's timezone: {moment().tz(remoteUser?.timezone).format('hh:mm A')}
          </span>
        </div>
      )}
    </Wrapper>
  );
};
