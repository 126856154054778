import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsBell } from 'react-icons/bs';
import { getNotifications, readNotification } from 'helpers/http/notification';
import './style.css';
import useUsers from 'pages/messaging/controllers/useUsers';
import { useAuth } from 'helpers/contexts/auth-context';

interface NotificationItem {
  title: string;
  notification_id: string;
  link: string;
  is_seen: number;
}

export const NotificationModal = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const { user } = useAuth();
  const notificationHandler = (notifications: NotificationItem[]) => {
    const unReadNotification = notifications.filter((notf: NotificationItem) => !notf.is_seen);

    const readNotification = notifications.filter((notf: NotificationItem) => !!notf.is_seen);
    return [...unReadNotification, ...readNotification];
  };

  useEffect(() => {
    if (user)
      getNotifications().then((res) => {
        const notifications = res?.notifications;
        if (notifications?.length > 0) {
          const notifyData = notificationHandler(notifications);
          setNotifications(notifyData);
        }
      });
  }, []);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest(`.not-main-wrapper`)) {
        setShow(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const toggle = () => setShow(!show);

  const seenNotification = (notification_id: string, link?: string) => {
    let updatedData = notifications.map((x) => (x.notification_id === notification_id ? { ...x, is_seen: 1 } : x));

    updatedData = notificationHandler(updatedData);
    setNotifications(updatedData);
    //eslint-disable-next-line
    link = link && link.replace(/^.*\/\/[^\/]+/, '');
    readNotification({ notification_id });
    if (link) navigate(link);
  };

  return (
    <div className="not-main-wrapper">
      <BsBell onClick={() => toggle()} size={30} className="me-4 not-icon cursor-pointer" />

      <div className={`notification-box ${!show && 'hide'}`}>
        <div className="notification-content">
          <>
            {!notificationHandler.length ? (
              <p className="no-notifications ">No notifications found</p>
            ) : (
              notifications.map((notification) => {
                const { is_seen, notification_id, title, link } = notification;
                return (
                  <p
                    key={`not-title-${notification_id}`}
                    className={`not-title ${!is_seen && 'bold'}`}
                    onClick={() => seenNotification(notification_id, link)}
                  >
                    {title}
                  </p>
                );
              })
            )}
          </>
        </div>
      </div>
      {/* <div className={`not-box ${show ? 'notification-box-visible' : ''}`}>
        {notifications.length === 0 ? (
          <div className="no-notifications w-100 h-100 d-flex align-items-center justify-content-center">
            <p className="mb-0">No notifications found.</p>
          </div>
        ) : (
          notifications.map((notification, index) => {
            const { is_seen, notification_id, title, link } = notification;
            return (
              <div
                className={`notification-box ${
                  is_seen === 0 ? `unseen-notification ${notification_id}` : ''
                }`}
                key={`not-key-${index}`}
              >
                <div className="d-flex align-items-center justify-content-between px-4">
                  <p
                    className="not-title py-3"
                    onClick={() => seenNotification(notification_id, link)}
                  >
                    {title}
                  </p>
                  {!is_seen && (
                    <div
                      className="ps-3 py-3 close-notification-icon-wrapper"
                      onClick={() => seenNotification(notification_id, '')}
                    >
                      <AiOutlineClose size={16} color={'#393939'} />
                    </div>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div> */}
    </div>
  );
};
