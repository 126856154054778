import PageTitle from 'components/styled/PageTitle';
import { Container } from 'react-bootstrap';
import * as M from './styled';
import Tabs from 'components/ui/Tabs';
import useResponsive from 'helpers/hooks/useResponsive';
import ChatSection from './ChatSection';

const NewMessaging = () => {
  const { isDesktop } = useResponsive();
  const activeChat = null;
  return (
    <Container className="mb-5 content-hfill">
      <PageTitle className="mt-4">Messages</PageTitle>
      <M.TabsContainer className="tabs mt-3">
        <Tabs
          tabs={[]}
          activeTab={''}
          onTabChange={(tb) => console.log('selected tabL ', tb)}
          breakPoint="1200px"
          className="tabs-container"
        />
      </M.TabsContainer>

      <M.MessageContainer>
        {!isDesktop ? (
          <div className="m-userlist--wrapper">
            This is mobile user list component
            {/* <UserList /> */}
          </div>
        ) : (
          <></>
        )}
        {isDesktop ? <div className="m-userlist--wrapper">This is desktop user list component</div> : null}
        {isDesktop || (!isDesktop && activeChat !== null) ? (
          // <ChatPanel />
          <ChatSection />
        ) : null}
      </M.MessageContainer>
    </Container>
  );
};

export default NewMessaging;
