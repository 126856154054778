export const HOME_PAGE_VIDEO =
  'https://zehmizeh-app-data.s3.amazonaws.com/files/site+pitch_1.mp4';

export const CLIENT_HOW_TO_PROJECT_BASED_PROJECTS =
  'https://zehmizeh-app-data.s3.amazonaws.com/files/How+to+-+project-based+projects.mp4';

export const PRIVACY_POLICY_LINK =
  'https://app.termly.io/document/privacy-policy/82c240f5-fcef-4182-8a8b-e62b1fa9141a';
export const COOKIES_POLICY_LINK =
  'https://app.termly.io/document/cookie-policy/2bf74211-d676-444a-bfaa-5d854a0a1807';

export const ZEHMIZEH_LINK = 'https://www.zehmizeh.com/home';
