import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getPaymentFees } from 'helpers/http/common';

const PaymentDetailsContxt = React.createContext<any>(null);

// todo: Mudit- To sometimes randomly poayment calculationsm, doesn't show up as per yogesh, right now not able to reproduce this
const PaymentProvider = ({ children }: any) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('OTHER');
  const [amount, setAmount] = useState('');
  const [jobType, setJobType] = useState('');

  const { data } = useQuery(['get-payment-fees'], () => getPaymentFees(), {
    keepPreviousData: true,
  });

  const feeStructure = data?.data[0]?.fee_structure;

  const updatePaymentMethod = React.useCallback((method: string) => {
    setSelectedPaymentMethod(method);
  }, []);

  const minFixedCharge = useMemo(() => {
    let minFixedChargeValue = 0;
    if (feeStructure) {
      minFixedChargeValue = feeStructure[selectedPaymentMethod]?.minFixedCharge;
    }
    return minFixedChargeValue;
  }, [feeStructure, selectedPaymentMethod]);

  const minFixedAmount = useMemo(() => {
    let minFixedAmountValue = 0;
    if (feeStructure) {
      minFixedAmountValue = feeStructure[selectedPaymentMethod]?.minFixedAmount;
    }
    return minFixedAmountValue;
  }, [feeStructure, selectedPaymentMethod]);

  const zehMizehCharge = useMemo(() => {
    let zehmizehFees = 0;
    if (feeStructure) {
      const zehmizehFeesPerc = feeStructure[selectedPaymentMethod]?.percentage;
      if (amount) {
        zehmizehFees = (parseFloat(amount) * zehmizehFeesPerc) / 100;
      }
      return zehmizehFees;
    }
  }, [selectedPaymentMethod, amount, feeStructure]);

  const totalPayableAmount = useMemo(() => {
    let totalPayable = 0;
    if (amount) {
      totalPayable = parseFloat(amount) + zehMizehCharge;
    }
    return totalPayable;
  }, [amount, zehMizehCharge]);

  const value = React.useMemo(
    () => ({
      selectedPaymentMethod,
      updatePaymentMethod,
      fees: feeStructure ? feeStructure[selectedPaymentMethod]?.percentage : 0,
      amount,
      setAmount,
      zehMizehCharge,
      totalPayableAmount,
      jobType,
      setJobType,
      minFixedCharge,
      minFixedAmount,
    }),
    [
      selectedPaymentMethod,
      updatePaymentMethod,
      feeStructure,
      amount,
      setAmount,
      zehMizehCharge,
      totalPayableAmount,
      jobType,
      setJobType,
      minFixedCharge,
      minFixedAmount,
    ]
  );

  return <PaymentDetailsContxt.Provider value={value}>{children}</PaymentDetailsContxt.Provider>;
};

function usePayments() {
  return React.useContext(PaymentDetailsContxt);
}

export { PaymentProvider, usePayments };
