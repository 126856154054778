import { StyledButton } from 'components/forms/Buttons';
import { FormWrapper } from './support.styled';

const FaqForm = () => {
  return (
    <FormWrapper>
      <div>
        <p>
          Are you confused about something you see on ZehMizeh? Would you like
          to learn more about how the site works? Click the button below to see
          our ZMZ Help Center, where we've collected answers to all of your most
          frequently asked questions!
        </p>
        <div className="my-4 text-center">
          <StyledButton
            onClick={() =>
              window.open('https://intercom.help/zehmizehfaq/en', '_blank')
            }
          >
            Take me to the Help Center!
          </StyledButton>
        </div>
      </div>
    </FormWrapper>
  );
};

export default FaqForm;
